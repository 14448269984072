
  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
  }
  .arrows-container{
    position: absolute;
    top: 843px;
    width: 100%;
    left: 20px;
    display: flex;
    justify-content: center;
    gap :50px
  }
  .img_slider{
    position: relative;
  }
  .my-prev-slide, .my-next-slide{
    width: 30px;
  height: 30px;
  padding: 4px;
  background-color: red;
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 96%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  transition: background-color 0.3s ease; 


  }

  