/* CSS for custom navigation arrows */
.swiper-button-prev,
.swiper-button-next {
  width: 30px;
  height: 30px;
  padding: 4px;
  /* background-color: red; */
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 96%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a transition for hover effect */
}
.swiper-button-next{
    background-image: url('../../assets/images/r-arrow.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.swiper-button-prev{
    background-image: url('../../assets/images/l-arrow.png');
    background-size: contain;
    background-repeat: no-repeat;
    

}
/* Styles for the previous button */
.swiper-button-prev {
  left: 44%;
}
.swiper-button-disabled{
  
    opacity: 0.5;
}

/* Styles for the next button */
.swiper-button-next {
  right: 38%; /* Adjust the position as needed */
}

/* Hover effect */
.swiper-button-prev:hover,
.swiper-button-next:hover {
  /* background-color: red; Change the background color on hover */
}
@media only screen and (max-width: 600px) {
  .mySwiper2 .swiper-button-next  {
    top:94%
  }
  .mySwiper2 .swiper-button-prev  {
    top:94%

  }
}
