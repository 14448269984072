
.banner_btn {
    gap: 10px;
    padding-top: 40px;
    display: flex;
  }

img.rightside_kids {
    /* width: 850px; */
    /* position: absolute; */
    /* object-fit: contain; */    
    margin-top: 54px;

    width: 100%;
    height: 100%;

  }
  .hero-section-container{
    display: flex;
    flex: 1;
  }

@media only screen and (max-width: 600px) {
    img.rightside_kids {
        display: none;
    }
}