.service-slider-img{
    height: 110px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;

  }
  
    /* .swiper-slide img {
      display: block;
      width: 50%;

      height: 100%;
      object-fit: cover;
    } */
  
  .swiper-slide {
    width: 60%;
    /* padding: 0rem 10rem; */
  }
  
  .swiper-slide:nth-child(2n) {
    /* width: 10%; */
  }
  
  .swiper-slide:nth-child(3n) {
    /* width:40%; */
    /* display: none; */
  }

  .mySwiper5 .swiper-button-prev  {
    width: 30px;
    height: 30px;
    padding: 4px;
    /* background-color: red; */
    /* border-radius: 50%; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 25%;
    transform: translateY(-50%);
    z-index: 10;
    left: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add a transition for hover effect */
    background-image: url('../../assets//images/arrow-l.png');

  }
.slider-wrapper-container{
  padding: 0rem 11rem;
}
  .mySwiper5 .swiper-button-next  {
    width: 30px;
    background-image: url('../../assets//images/arrow-r.png');

    height: 30px;
    padding: 4px;
    /* background-color: red; */
    /* border-radius: 50%; */
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 25%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Add a transition for hover effect */


  }
@media only screen and (max-width: 600px) {
  .mySwiper5 .swiper-button-next  {
    display: none;
    top:94%
  }
  .slider-wrapper-container{
    padding: 0rem 0rem;
  }
  .mySwiper5 .swiper-button-prev  {
    display: none;
    
  }
}
  
  
  