@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body .fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
.hero-btn {
  border: 2px solid #8d42c1;
  border-radius: 28px;
  padding: 0.5rem 2rem;
}
.live-chat {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  cursor: pointer;
}

.header-wrapper {
  background: rgb(187 121 233 / 80%);

  /* background-color: rgba(255, 255, 255, 0.3); */
  /* background-image: url(../../assets/images/hero-banner.png); */
  /* opacity: .4; */
  /* backdrop-filter: blur(6px); */
  color: #000;
  /* padding: 8px 0; */
  box-shadow: 0px 4px 4px 0px #0000001a;
  position: sticky;
  top: 0;
  z-index: 9999999;
  /* float: left; */
  width: 100%;
  font-family: "Poppins";
}
.nav-1 {
  padding: 1rem 4rem;
  display: flex;
  justify-content: space-between;
}
.section-1 .container .midContent p {
  padding-right: 10px;
}

.navShotBar.full-section {
  display: none;
}
.navShotBar.full-section {
  display: none;
}

.footer-contact.full-row {
  display: none;
}

.footer-links.full-row {
  display: none;
}

.footer-logos.clearfix {
  display: none;
}

.footer-bottom.full-row {
  background: #1a614f !important;
}
.quote img {
  filter: grayscale(1);
}
.res-show {
  display: none;
}
.header-right{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}
@media only screen and (max-width: 600px) {
  .display-none {
    display: none;
  }
  .hero-btn{
    padding: 0px;
  }
  .header-right{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
  .mob{
    gap: 5px !important;
  }
  .live-chat{
    font-size: 12px;
    padding: 0rem .5rem;
  }
  .nav-1 {
    padding: 1rem 1rem;
  }
  .logo-nav{
    width: 150px;
    height: 60px;
  }
 
}
