.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}





* {
  margin: 0px;
  padding: 0px;
}

@font-face {
  font-family: 'Rusty Hooks';
  src: 
  url('./assets/images/Fonts/RustyHooks-Regular.woff') format('woff2'),
      url('./assets/images/Fonts/RustyHooks-Regular.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham';
  src: url('.//assets/images/Fonts/gotham_medium/Gotham-Medium.woff2') format('woff2'),
      url('./assets/images/Fonts/gotham_medium/Gotham-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
body{
  background-image: url(./assets/images/hero-banner.png);
  background-size: cover;

}
/* banner section css */

.banner_section {
  background-image: url(./assets/images/hero-banner.png);
  background-position: center;
  background-size: cover;
  /* height: 970px; */
  z-index: 33;
  margin-bottom: -77px;
  position: relative;
}
.logo img {
  width: 250px;
  padding-top: 30px;
}

h1.banner_heading {
  font-family: 'Rusty Hooks';
  color: white;
  font-size: 41px;
  font-weight: 500;
}

span.yellow {
  color: #f4ca55;
}

button.btn-close.avail {
  background-color: #f4ca55;
  color: #bc79ea;
  font-family: 'Rusty Hooks';
  border: 3px solid #c79f30;
  padding: 10px 25px 10px 25px;
  border-radius: 40px;
  font-size: 20px;
}

button.btn-close.pricing {
  font-family: 'Rusty Hooks';
  border: 3px solid #c79f30;
  padding: 10px 25px 10px 25px;
  border-radius: 40px;
  background-color: transparent;
  color: #f4ca55;
  font-size: 20px;
}





.content {
  padding-top: 20px;
}

/* banner section css end */
/* services section css */
.services_sections {
  background-color: #bb79e9;
  padding-bottom: 60px;
  padding-top: 125px;
  z-index: 6;
}

img.service_kid {
  /* width: 885px; */
  position: absolute;
  /* margin-left: -118px; */
  top: -32px;
  height: 634px;
}

ul.servis {
  display: flex;
  list-style: none;
}

ul.servis li img {
  width: 100%;
}

.inner_services h2 {
  font-family: 'Rusty Hooks';
  font-size: 52px;
  color: white;
}

p.services_deptn {
  font-size: 19px;
  font-family: 'Gotham';
  font-weight: 500;
  color: white;
}

/* reader discover section css */
.reader_discover-1{
  background-image: url(./assets/images/newlins.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 330px;
}
section.reader_discover {

  padding-top: 90px;
  padding-bottom: 20px;
  width: 60%;
}
.discover-right-img{
  top: -323px;
  left: 57px;
}

button.btn-close.availperpal {
  background-color: #8d42c1;
  color: white;
  font-family: 'Rusty Hooks';
  border: 3px solid #5f2488;
  padding: 10px 25px 10px 25px;
  border-radius: 40px;
  font-size: 14px;
}

button.btn-close.pricingperpal {
  font-family: 'Rusty Hooks';
  border: 3px solid #5f2488;
  padding: 0px 29px;
  border-radius: 40px;
  background-color: transparent;
  color: #8d42c1;
  font-size: 14px;
}
.reader_content{
  /* width: 80%; */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.pricingperpal-2{
  font-family: 'Rusty Hooks';
  border: 3px solid #5f2488;
  padding: 0px 29px;
  border-radius: 40px;
  background-color:#8d42c1 ;
  color: #fff;
  font-size: 14px;
}
.reader_content h3 {
  font-family: inherit;
  font-weight: 500;
  font-size: 22px;
  font-family: Gotham;
}

span.perpel {
  color: #8d42c1;
}

/* .reader_discover:before {
  content: "";
  background-image: url(./assets/images/left-book.png);
} */
img.right {
  /* position: absolute;
  left: -179px; */
  margin-top: 81px;
  width: 432px;
  /* top: -90px; */
  z-index: 9999;
}
.left-img{
  margin-bottom: 40px;
}

img.left {
  width: 335px;
    /* position: absolute;
  top: -126px;
  left: 56px; */
}

/* sldier section css */
.left-image-container{

}
.footer-right-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

section.silder_section {
  background-image: url(./assets/images/hero-banner.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1rem 0rem;
  /* height: 972px; */
  /* z-index: -1; */
  /* margin-bottom: -42px; */
  position: relative;
}
.slider_contetn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
h1.slider_heading {
  font-size: 42px;
  font-family: 'Rusty Hooks';
  color: white;
  padding-top: 130px;
  padding-bottom: 41px;
}

.slick-slide img {
  display: block;
  width: 100%;
  height: auto;
}

/* packages css */
section.package {
  background-image: url(./assets/images/newlins.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* display: flex; */
  align-items: center;
  padding-top: 120px;
  padding-bottom: 35px;
  height: auto;
  z-index: 6;
  position: relative;
}

.package-one {
  background-image: url(./assets/images/package-purple.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  padding: 34px;
  text-align: center;
}

ul.pak_ul li {
  list-style: none;
  /* padding-left: 35px; */
  padding: 0rem 1rem;
  /* white-space: nowrap; */
  color: white;
  font-family: 'Gotham';
  font-weight: 500;
  font-size: 12px;
}

.package-one h3 {
  color: white;
  font-family: 'Rusty Hooks';
  font-size: 32px;
  font-weight: 400;
  padding-bottom: 25px;

}

button.btn-close.pricingpkg {
  background: white;
  color: #8d42c1;
  padding: 6px 20px 7px 20px;
  border-radius: 33px;
  border: none;
  font-family: 'Gotham';
  margin-top: 25px;
}

.package-two {
  background-image: url(./assets/images/package-yellow.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-color: #f4ca55; */
  border-radius: 20px;
  padding: 34px;
  text-align: center;
}

.package-two h3 {
  color: white;
  font-family: 'Rusty Hooks';
  font-size: 32px;
  font-weight: 400;
  padding-bottom: 25px;

}

button.btn-close.pricingtwo {
  background: white;
  color: #8d42c1;
  padding: 6px 20px 7px 20px;
  border-radius: 33px;
  border: none;
  font-family: 'Gotham';
  margin-top: 25px;
}

.content_pkg h2 {
  font-family: 'Rusty Hooks';
  font-size: 32px;
  text-align: start;
}

.pkg_kidsimg img {
  width: 270px;
  /* margin-left: -43px; */
  margin-top: 37px;
}

/* book publishing services */
p.book_servicsp {
  font-family: 'Gotham';
  font-size: 19px;
}

.slide_contents h2 {
  font-family: 'Rusty Hooks';
  font-size: 53px;
}

.bkservices_rightimg img {
  width: 640px;
  position: absolute;
  margin-left: 39px;
}

section.book_publishing-services {
  display: flex;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 53px;
  z-index: 5;
  background: #fff;
}

/* icons slider */
.wrapper {
  width: 100%;
  padding-top: 20px;
  text-align: center;
}

.carousel {
  width: 90%;
  margin: 0px auto;
}

.slick-slide {
  margin: 10px;
}

h3.timeless {
  font-family: 'Gotham';
  font-size: 20px;
  font-weight: 500;
  color: #282c34;
}

/* cta section*/
section.callto_action {
  background-image: url(./assets/images/newlins.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.banner_btns {
  display: flex;
  gap: 10px;
  /* padding-bottom: 30px; */
}

.call_img img {
  width: 664px;
  margin-left: -146px;
  /* margin-top: -50PX; */
  /* height: 400px; */
  position: relative;
  top: -70px;
}
section.step_slider {
  background-color: #b875e6;
  padding-bottom: 50px;
  padding-top: 30px;
  z-index: 6;
  position: relative;
}

.cta_content h2 {
  font-family: 'Rusty Hooks';
  font-size: 34px;
  color: #8d42c1;
}

.cta_content p {
  font-family: 'Gotham';
  font-size: 24px;
}

.cta_content {
  /* padding-top: 85px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* footer section css */
footer.footer_Sec {
  background-color: #8d42c1;
  display: flex;
  align-items: center;
}
.ftr_content{
  display: flex;
  height: 100%;
  flex-direction: column;
}
.footer-main-container{
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.ftr_content h2 {
  /* font-family: 'Rusty Hooks'; */
  font-size: 30px;
  font-weight: 700;
  color: white;
}

.ftr_content p {
  color: white;
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 17px;
}
.col-md-6.ftr {
  border-right: 3px solid white;
  padding-bottom: 45px;
  padding-top: 45px;
}

.ftr_forms {
  background-image: url(./assets/images/formbg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 311px;
  width: 327px;


  position: relative;

  margin: 50px 0px ;

  /* z-index: 999999999999 !important; */

}

input.nav-item {
  padding: 6px;
  border-radius: 20px;
  font-family: 'Gotham';
  font-weight:100;
  /* margin-right: 5px; */
  /* width: 109px; */
  width: 100%;
  margin-bottom: 10px;
  border: 2px solid #242424;
  background-color:transparent;

}

textarea.form-control {
  height: auto;
  /* width: 220px; */
  padding: 1px;
  border-radius: 20px;
  border: 2px solid black;
  margin-bottom: 10px;
  background-color: transparent;
}

.ftr_forms H2 {
  font-family: 'Rusty Hooks';
  color: #8d42c1;
  font-size: 31px;
  padding-left: 53px;
  /* padding-top: 93px; */
}
.footer-kid-img{
  position: absolute;
  height: 350px;
  width: 200px;
  margin-left: -98px;
  margin-top: -47px;

  
}
.forms {
  padding-top: 34px;
  padding-left: 51px;
  padding-right: 5px;
}

section.lastftr {
  background: #bb99d2;
  padding-top: 10px;
  padding-bottom: 10px;
}

.lastft p {
  font-family: 'Gotham';
  font-size: 14px;
  color: black  ;
}

.terms p {
  color: black;
  font-family: 'Gotham';
  font-size: 14px;

  padding-top: 14px;
  text-align: end;
}

/* new */
.left, .pkg_kidsimg img, .lead-testimonial-cont:before, img.cube_3 {
  animation: float 4s ease-in-out infinite;
}

@keyframes float {
  0% {
      transform: translatey(0px);
  }

  50% {
      transform: translatey(-50px);
  }

  100% {
      transform: translatey(0px);
  }
}

.slick-next:before {
  content: '→';
  color: #f4ca55 !important;
}

.slick-prev:before {
  content: '←';
  color: #f4ca55 !important;
}

.slick-arrow,
.slick-arrow:hover {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  background-image: unset;
  padding: unset;
}

.slick .slick-prev::before {
  content: '\f0a8';
  font-family: 'Font Awesome 5 Free';
}

.slick .slick-next::before {
  content: '\f0a9';
  font-family: 'Font Awesome 5 Free';
}

.slick .slick-prev::before {
  content: '\f0a8';
  font-family: 'Font Awesome 5 Free';
}

.slick .slick-next::before {
  content: '\f0a9';
  font-family: 'Font Awesome 5 Free';
}

button.slick-prev.slick-arrow {
  left: 226px !important;
  font-size: 20px !important;
  width: 99px !important;
  z-index: 113 !important;
  height: 41px !important;
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 34px !important;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button.slick-next.slick-arrow {
  width: 110px !important;
  height: 100px !important;
}

.slick-next {
  right: 180px !important;
}

.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 100% !important;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

/* .slick-track {
  padding-bottom: 25px !important;
} */
.book_servicesslide button.slick-next.slick-arrow {
  top: 40% !important;
  right: 68px;
  margin-right: -266px;
}

.book_servicesslide .slick-next:before {
  color: #5c2284 !important;
}

.book_servicesslide button.slick-prev.slick-arrow {
  top: 40% !important;
  margin-left: -282px;
}

.book_servicesslide .slick-prev:before {
  color: #5c2284 !important;
}

section.silder_section .slick-next:before {
  color: white !important;
}

section.silder_section .slick-prev:before {
  color: white !important;
}

section.step_slider button.slick-next.slick-arrow {
  left: 58%;
}

section.step_slider button.slick-prev.slick-arrow {
  left: 49% !important;
}

/* testimoinals css */
section.testimoials {
  background-image: url(./assets/images/testimonal-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  height: 770px;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

.lead-testimonial-cont {
  width: 100%;
  max-width: 1043px;
  min-width: 870px;
  height: 420px;
  margin: 0 auto;
  background-color: #592080;
  padding: 30px;
  position: relative;
  box-shadow: -4px 5px 4px 4px rgba(0,0,0,0.75);
}

.lead-testimonial-cont>h2 {
  position: absolute;
  text-align: center;
  margin: -55px 0 0 0;
  font-size: 14px;
  left: 10px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #444;
}

.lead-tstmnl-slider {
  width: 100%;
  height: 430px;
  float: left;
  /* overflow: hidden; */
}

.lead-tstmnl-slide {
  width: 100%;
  max-width: 1140px;
  height: 100%;
  float: left;
}

.lead-tstmnl-slide:focus {
  outline: none;
}

.lead-tstmnl-slide .lead-tstmnl-img {
  float: left;
  width: 160px;
  height: 160px;
  /* border-radius: 100%;
  border: 5px solid rgba(0, 0, 0, 0.08);
  overflow: hidden;
  margin: 8px 30px 0 0; */
}

.lead-tstmnl-slide .lead-tstmnl-img img {
  width: 100%;
}

.lead-tstmnl-text-cont {
  float: left;
  width: 82.8%;
  height: 168px;
  position: relative;
}

.lead-tstmnl-comment {
  float: left;
  display: block;
  width: 66.5%;
  position: relative;
  padding: 20px 25px;
  max-height: 120px;
  overflow: hidden;
  font-size: 15px;
  line-height: 24px;
  color: white;
  font-family: 'Gotham';
}

.lead-tstmnl-comment:before,
.lead-tstmnl-comment:after {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
}

.lead-tstmnl-comment:before {
  top: 0;
  left: 0;
}

.lead-tstmnl-comment:after {
  bottom: 0;
  right: 0;
  background-position: 20px 0;
}

.lead-tstmnl-nm-dsg {
  position: absolute;
  bottom: 0;
  left: 0;
}

.lead-tstmnl-nm-dsg li {
  display: inline-block;
  float:left;
  padding: 0 15px;
  border-left: 1px solid #6b6b6b;
  font-size: 15px;
  line-height: 14px;
  letter-spacing: 0.5px;
  color: #6b6b6b;
}

.lead-tstmnl-nm-dsg li:first-child {
  border-left: 3px solid #e73022;
  padding-left: 10px;
  font-weight: 700;
  color: #e73022;
}

.slick-dots button {
  display: none;
}

.lead-tstmnl-slider .slick-dots {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.lead-tstmnl-slider .slick-dots li {
  list-style: outside none none;
  display: inline-block;
  cursor: pointer;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin: 0px 3px 0px 0px;
  background-color: #eee;
  position: relative;
}

.lead-tstmnl-slider .slick-dots li.slick-active {
  background-color: #e73022;
}

h2.client_name {
  font-family: 'Rusty Hooks';
  color: white;
  font-size: 36px;
}
section.testimoials h2 {
  font-family: 'Rusty Hooks';
  font-size: 47px;
}
h2.testimanh {
  font-family: 'Rusty Hooks';
  font-size: 47px;
  text-align: center;
  padding-left: 229px;
}
section.testimoials button.slick-prev.slick-arrow {
  margin-bottom: 20px !important;
  /* height: 135px !important; */
  /* width: 200px !important; */
  margin-left: 170px;
}
section.testimoials button.slick-next.slick-arrow {
  margin-right: 90px;
}
.lead-tstmnl-slide.slick-slide.slick-current.slick-active {
  padding-top: 30px;
}
.lead-testimonial-cont:before {
  content: '';
  background-image: url(./assets/images/cube-2.png);
  width: 231px;
  height: 350px;
  position: absolute;
  bottom: -150px;
  background-repeat: no-repeat;
  left: -184px;
}
.lead-testimonial-cont:after {
  content: '';
  background-image: url(./assets/images/quote.png);
  width: 148px;
  height: 109px;
  position: absolute;
  background-repeat: no-repeat;
  right: 0px;
  background-size: contain;
  top: -35px;
  opacity: 0.5;
}
section.testimoials:after {
  content: '';
  background-image: url(./assets/images/cube.png);
  width: 327px;
  height: 472px;
  position: absolute;
  background-repeat: no-repeat;
  right: 1px;
  margin-bottom: -42%;
  background-size: contain;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  /* scrollbar-color: #8f54a0 #ffffff; */
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
  border-radius: 10px;

}
*::-webkit-scrollbar-track{
  background:'#fff';
  border-radius: 10px;

  /* width: 100px; */
}
*::-webkit-scrollbar-track {
  /* background: #ffffff; */
  border: 1px solid #fff;

}
*::-webkit-scrollbar-thumb {
  background-color: #8d42c1; 
  border-radius: 6px;
}


.purpleSlider::-webkit-scrollbar-thumb {
  background-color: #8d42c1; 
  /* background-image: url(./assets/images/arrow.png); */
  /* bac */
  background-position: center;
  background-size:  contain;
  background-repeat: no-repeat;
  border-radius: 10px;
  border: 1px solid #fff;
}
.orangeSlider::-webkit-scrollbar-thumb {
  background-color: #f4ca55; 
  /* background-image: url(./assets/images/arrow.png); */
  /* bac */
  background-position: center;
  background-size:  contain;
  background-repeat: no-repeat;
  border-radius: 10px;
  border: 1px solid #fff;
}
.orange-color-text {
  color: f4ca55;
}

img.cube_3 {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 146px;
  margin-right: -151px;
}
h2.process {
  font-family: 'Rusty Hooks';
  font-size: 52px;
  text-align: center;
  color: white;
}
/* responsive css */








/* PRICING PAGE  */
.package_details_page {
  text-align: center;
}
.package_details_page .package_details_banner {
  height: 40vh;
}
.package_details_page .package_details_banner h1 span {
  color: #b5ed3f;
}
.package_details_page .package_details_section {
  background-color: #e8ffc7;
  border-radius: 10px;
  margin: 28px 0;
  padding: 60px 30px;
  text-align: left;
}
.package_details_page .package_details_section .package_details_inner_row {
  justify-content: space-between;
  padding-top: 30px;
}
.btn_blue {
  background-color: #b5ed3f;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0 10px;
  padding: 10px 15px;
  text-decoration: none;
  text-transform: uppercase;
}
button a {
  color: #032e32!important;
}
.btn_pink, button a {
  text-decoration: none;
}






@media only screen and (max-width: 600px) {
  .reader_discover-1{
    flex-direction: column;
    height: auto;
  }
  .pkg_kidsimg img {
    margin-left: 46px;
    margin-top: 36px;
  }
  .bkservices_rightimg img {
    position: static;
    margin: 0px;
  }
  .availperpal{

  }



  .process-slider-img{
    height: 200px;

  }
.footer-main-container{
  flex-direction: column;
}
  
  img.brand-logo {
      width: 100%;
  }
  .inner_services h2 {
      font-family: 'Rusty Hooks';
      font-size: 35px;
      color: white;
      padding-top: 323px;
  }
  img.service_kid {
      width: 374px;
      margin-left: -15px;
      height:331px ;
  }
  .inner_services h2 {
      font-family: 'Rusty Hooks';
      font-size: 35px;
      color: white;
      /* padding-top: 650px; */
  }
  img.right {
      /* display: none; */
      margin-top: 0px;
      left: -70px;
      top: -160px;
      height: 200px;
      width: 300px;
  }
  /* .reader_content {
      padding-bottom: 140px; */
  /* } */
  button.btn-close.pricingperpal {
    padding: 11px 28px;
  margin-left: 0px;

      /* height: 30px; */
      /* margin-top: 15px; */
  }
  .call-action-container{
    position: relative;
  }
  .call_img{
    /* display: none; */
    position: absolute;
  }
  button.btn-close.pricing{
      /* padding: 11px 28px;      ; */
      /* margin-top: 15px; */
  }
  form{
    margin-left: 100px;
    margin-top: 50px;
  }
  
h1.slider_heading {
  font-size: 35px;
  font-family: 'Rusty Hooks';
  color: white;
  padding-top: 27px;
  padding-bottom: 41px;
}
.divider-footer{
  display: none;
}
img.left {
  /* left: 141px;
  width: 218px; */
  display: none ;
}
.package-one {
  margin-bottom: 15px;
}
.package-two{
  margin-bottom: 15px;
}
.content_pkg h2 {
  font-size: 35px;
}
.slide_contents h2 {
  font-size: 35px;
}
.book_servicesslide button.slick-prev.slick-arrow {
  margin-left: -254px;
}
.book_servicesslide button.slick-next.slick-arrow {
  margin-right: -247px;
}
.bkservices_rightimg img {
  width: 332px;
  padding-top: 40px;
}
section.book_publishing-services {
  padding-top: 50px;
  /* padding-bottom: 390px; */
  padding-bottom: 10px;
}
h2.process {
  font-size: 33px;
}
h2.testimanh {
  font-size: 35px;
  padding-left: 10px;
}
section.testimoials:after{
  display: none;
}
h2.client_name {
  font-size: 20px !important;
}
.lead-tstmnl-comment {
  /* width: 29%; */
  width: 100%;
  position: relative;
  /* padding: 20px 25px; */
  max-height: 120px;
  overflow: hidden;
  font-size: 8px;
  line-height: 24px;
}
.lead-tstmnl-slide .lead-tstmnl-img {
  float: left;
  width: 70px;
  height: 160px;
}
button{
  white-space: nowrap;
  font-size: 12px !important;
}
.avail{
  white-space: nowrap;
  font-size: 12px !important;
  padding: 5px !important;
}
.pricing{
  white-space: nowrap;
  font-size: 12px !important;
  padding: 20px !important;
}
.img-dicount-section{
  height: 226px;
  width: 100%;
  margin-top: -45px;
}
.lead-testimonial-cont {
  width: 100%;
  max-width: 279px;
  min-width: 315px;
  height: 300px;
  margin: 0 auto;
  background-color: #592080;
  padding: 30px;
  position: relative;
  box-shadow: -4px 5px 4px 4px rgba(0,0,0,0.75);
}
.lead-testimonial-cont:before {
  content: '';
  background-image: url(./assets/images/cube-2.png);
  width: 110px;
  height: 256px;
  position: absolute;
  bottom: -137px;
  background-repeat: no-repeat;
  left: -45px;
  background-size: contain;
}
img.cube_3 {
  display: none;
}
section.testimoials button.slick-next.slick-arrow {
  margin-right: -135px;
  margin-top: -102px;
}
section.testimoials button.slick-prev.slick-arrow {
  margin-bottom: 20px !important;
  margin-left: -113px;
  margin-top: -101px;
}
.call-action-container{
  height: 500px !important;
}
.call_img img {
  width: 300px;
  margin-top: -100px;
  left: 165px;
}
.cta_content h2 {
  font-size: 35px;
  padding-top: 88px;
}
.footer-input-flex{
  flex-direction: column;
}
.ftr_forms H2 {
  font-family: 'Rusty Hooks';
  color: #8d42c1;
  font-size: 29px;
  padding-left: 140px;
  padding-top: 93px;
}
.email-field{
  width: 60% !important;
}
/* .no-pad-container{
  margin: 0px !important;
  padding: 0px;
} */
.callto_action{
  height: 600px !important;
}
input.nav-item {
  padding: 10px;
  border-radius: 20px;
  font-family: 'Gotham';
  font-weight: 200;
  width: 100%;
  /* margin-bottom: 10px; */
  border: 2px solid #242424;
}
textarea.form-control {
  height: auto;
  width: 100%;
}
.forms {
  padding-top: 40px;
  padding: 30px;
}
.ftr_forms {
  /* background-image: url(./assets/images/newkd.png); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  width: auto;
  margin: 0px;
  /* z-index: 999999999999 !important; */
}
.lastft p {
  font-family: 'Gotham';
  font-size: 12px;
  color: white;
}
.terms p {
  color: white;
  font-size: 12px;
  font-family: 'Gotham';
  padding-top: 7px;
  text-align: center;
}
.content {
  padding-top: 40px;
}
.footer-kid-img{
  top: 120px;
  left: 90px;
}
button.btn-close.pricing {
    padding: 11px 28px !important;
}
button.btn-close.avail {
  padding: 11px 28px !important;
}
.email-field{
  width: 100% !important;
}
h1.banner_heading {
  font-family: 'Rusty Hooks';
  color: white;
  font-size: 39px;
  font-weight: 500;
}
.banner_section {
  background-image: url(./assets/images/hb-bg\ .png);
  /* background-image: url(./assets/images/hb-bg\ .png); */
  background-position: center;
  background-size: cover;
  height: 640px;
  z-index: 33;
  margin-bottom: -77px;
  position: relative;
}
}
/* iPhone 6, 7, and 8 Landscape */
@media only screen and (min-width: 376px) and (max-width: 667px) {
}