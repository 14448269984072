.lead-tstmnl-slide{
    display: flex;
    text-align: start;
    padding: 0px;
    margin: 0px;
    padding-bottom: 50px;
    justify-content: center;
    align-items: center;
}
.lead-tstmnl-slide > p{
    text-align: start;
    padding: 0px;
    margin: 0px;
}

@media only screen and (max-width: 600px) {
    .mySwiper1  .swiper-button-next {
        top: 93%;
        right: 33%;

    }
    .mySwiper1  .swiper-button-prev {
        right: 33%;
        top: 93%;

    }
}